import React from "react";
import useLocationIdStore from "../../stores/useLocationIdStore/useLocationIdStore";
import LocationIdDoesNotExist from "../LocationIdDoesNotExist/LocationIdDoesNotExist";

interface IClientRouteGuard {
  children: JSX.Element;
}

function ClientRouteGuard({ children }: IClientRouteGuard) {
  const { locationId, welldReturnToUrl } = useLocationIdStore();
  if (!locationId || !welldReturnToUrl) {
    return <LocationIdDoesNotExist />;
  }
  return children;
}

export default ClientRouteGuard;
