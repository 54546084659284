import React from "react";
import useBootStore from "../../stores/useBootStore/useBootStore";

function CustomerSupport() {
  const bootStore = useBootStore.getState().boot;

  return (
    <div>
      <ul>
        <li className="support-list">
          If you believe you are a Tivity Health member under the SilverSneakers
          program, please contact Tivity Health customer support center at{" "}
          <a href={`tel:${bootStore?.silverSneakersPhoneNumber}`}>
            {bootStore?.silverSneakersPhoneNumber}
          </a>
          .
        </li>
        <li className="support-list">
          If you believe you are a Tivity Health member under the Prime program
          (provided by your health plan or employer), please contact Tivity
          Health Prime customer support center at{" "}
          <a href={`tel:${bootStore?.primePhoneNumber}`}>
            {bootStore?.primePhoneNumber}
          </a>
          .
        </li>
        <li className="support-list">
          If you are a network location serving a Tivity Health member, please
          contact Tivity Health partner service support number at{" "}
          <a href={`tel:${bootStore?.plPhoneNumber}`}>
            {bootStore?.plPhoneNumber}
          </a>
          .
        </li>
      </ul>
    </div>
  );
}

export default CustomerSupport;
