import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import useLocationIdSetter from "../../stores/useLocationIdSetter/useLocationIdSetter";
import useLocationIdStore from "../../stores/useLocationIdStore/useLocationIdStore";
import useUserFormStore from "../../stores/useUserFormStore/useUserFormStore";
import NotFound from "../../pages/NotFound/NotFound";
import { formatDays } from "../../util/util";

function LocationIdSetter() {
  const { pathname } = useLocation();
  const { setLocationId, setWelldReturnToUrl, welldReturnToUrl, locationId } =
    useLocationIdStore();
  const { setUserForm } = useUserFormStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (
      (searchParams.get("locationId") && searchParams.get("welldParameter")) ||
      locationId !== null ||
      welldReturnToUrl !== null
    ) {
      setLocationId(searchParams.get("locationId") ?? "");
      setWelldReturnToUrl(searchParams.get("welldParameter") ?? "");
      setUserForm({
        firstName: searchParams.get("firstName") ?? "",
        lastName: searchParams.get("lastName") ?? "",
        zipCode: searchParams.get("zip") ?? "",
        email: searchParams.get("email") ?? "",
        month: searchParams.get("dateOfBirth")
          ? formatDays(searchParams.get("dateOfBirth")?.split("/")[0]) ?? ""
          : "",
        day: searchParams.get("dateOfBirth")
          ? formatDays(searchParams.get("dateOfBirth")?.split("/")[1]) ?? ""
          : "",
        year: searchParams.get("dateOfBirth")
          ? searchParams.get("dateOfBirth")?.split("/")[2] ?? ""
          : "",
      });
      pathname.replace("/", "");
      navigate("/eligibility");
    }
  }, [
    locationId,
    pathname,
    navigate,
    setLocationId,
    setWelldReturnToUrl,
    welldReturnToUrl,
    setUserForm,
    searchParams,
  ]);
  return <NotFound />;
}

export default LocationIdSetter;
