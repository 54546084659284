import { create } from "zustand";
import { persist } from "zustand/middleware";

const useTokenStore = create(
  persist<{
    token: string | null;
    setToken: (token: string) => void;
  }>(
    (set) => ({
      token: null,
      setToken: (token: string) => {
        set({
          token,
        });
      },
    }),
    {
      name: "token-store",
      getStorage: () => sessionStorage,
    }
  )
);

export default useTokenStore;
