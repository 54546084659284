import React, { useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, Link } from "react-router-dom";
import { EligibilityFormTypes } from "./EligibilityForm.types";
import { getDays, getMonths, getYears } from "../../util/util";
import {
  EligibilityFormResponse,
  EligibilityFormSubmit,
} from "../../api/queries.types";
import { checkEligibilityWelld } from "../../api/queries";
import useTokenStore from "../../stores/useTokenStore/useTokenStore";
import STATUS from "../../pages/Eligibility/Eligibility.types";
import useAlertStore from "../../stores/useAlertStore/useAlertStore";
import ALERTS from "../../util/Alerts";
import useLocationStore from "../../stores/useLocationIdStore/useLocationIdStore";
import useUserFormStore from "../../stores/useUserFormStore/useUserFormStore";
import useBootStore from "../../stores/useBootStore/useBootStore";
import AngleRight from "../../assets/angle-right.png";
import CustomerSupport from "../CustomerSupport/CustomerSupport";

const schema = yup.object({
  firstName: yup.string().min(2).required(),
  lastName: yup.string().min(2).required(),
  day: yup.string().required(),
  month: yup.string().required(),
  year: yup.string().required(),
  email: yup.string().email().required(),
  healthPlan: yup.string().optional(),
  // subscribeNewsletter: yup.boolean(),
  zipCode: yup.string().required(),
});

interface IEligibilityFormProps {
  multipleAccountsFoundForm: boolean;
}

function EligibilityForm({ multipleAccountsFoundForm }: IEligibilityFormProps) {
  const navigate = useNavigate();
  const { setToken } = useTokenStore();
  const { setAlert } = useAlertStore();
  const { setUserForm } = useUserFormStore();
  const bootStore = useBootStore.getState().boot;
  const { welldReturnToUrl, locationId } = useLocationStore();

  const [loading, setLoading] = useState(false);
  // const [multipleAccountsFoundForm, setMultipleAccountsFoundForm] =
  //   useState(false);
  const checkEligibilityMutation = useMutation<
    EligibilityFormResponse,
    any,
    EligibilityFormSubmit,
    any
  >((form) => checkEligibilityWelld(form), {
    onSuccess: (data) => {
      setLoading(false);
      if (data.status === STATUS.FOUND_ELIGIBLE) {
        setToken(data.jwt);
        navigate("/success");
      } else if (data.status === STATUS.MULTIPLE_ACCOUNTS_FOUND) {
        navigate("/multiple-accounts-found");
      } else if (data.status === STATUS.FOUND_NOT_ELIGIBLE) {
        navigate("/partial/success");
      } else if (data.status === STATUS.NOT_FOUND) {
        navigate("/declined");
      } else {
        navigate("/declined");
      }
    },
    onError: () => {
      setLoading(false);
      setAlert(ALERTS.ERROR);
    },
    retry: false,
  });
  const { firstName, lastName, email, zipCode, month, day, year, healthPlan } =
    useUserFormStore();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<EligibilityFormTypes>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      day: typeof day === "string" && day.length > 1 ? day : "01",
      month: typeof month === "string" && month.length > 1 ? month : "01",
      year: typeof year === "string" && year.length > 1 ? year : "2020",
      zipCode: zipCode ?? "",
      healthPlan: healthPlan ?? "",
      // subscribeNewsletter: true,
    },
  });
  // removed news letter
  const convertToSubmitForm = (
    values: EligibilityFormTypes
  ): EligibilityFormSubmit => ({
    firstName: values.firstName,
    lastName: values.lastName,
    zip: values.zipCode,
    dateOfBirth: `${values.month}/${values.day}/${values.year}`,
    email: values.email,
    locationId: locationId ?? "",
    // subscribeNewsletter: values.subscribeNewsletter,
  });

  const handleFormSubmit = handleSubmit((values) => {
    setLoading(true);
    const mappedValues = convertToSubmitForm(values);
    if (multipleAccountsFoundForm) {
      mappedValues.healthPlanId = values.healthPlan;
    }
    checkEligibilityMutation.mutate(mappedValues);
  });

  const values = getValues();
  useEffect(() => {
    if (multipleAccountsFoundForm && values.firstName === "") {
      navigate("/");
    }
  });
  const emailWatch = useWatch({
    control,
    name: "email",
  });
  const healthPlanWatch = useWatch({
    control,
    name: "healthPlan",
  });
  useEffect(() => {
    setUserForm({
      ...values,
      email: emailWatch,
      healthPlan: healthPlanWatch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailWatch, healthPlanWatch]);

  useEffect(() => {
    if (!multipleAccountsFoundForm) {
      setValue("healthPlan", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleAccountsFoundForm]);

  return (
    <Form onSubmit={handleFormSubmit}>
      {multipleAccountsFoundForm && values.firstName !== "" ? (
        <>
          <div className="form-container-multiple-details shadow border-0">
            <div
              style={{ color: "#5D5D5D", fontSize: "14px" }}
              className="d-flex flex-row justify-content-start mb-0 mt-1"
            >
              <div style={{ flex: `0 1 20%` }}>Name:</div>
              <div className="flex-2">
                {values.firstName} {values.lastName}
              </div>
            </div>
            <div
              style={{ color: "#5D5D5D", fontSize: "14px" }}
              className="d-flex justify-content-start mb-0 mt-1"
            >
              <div style={{ flex: `0 1 20%` }}>Email:</div>
              {values.email}
            </div>
            <div
              style={{ color: "#5D5D5D", fontSize: "14px" }}
              className="d-flex justify-content-start mb-0 mt-1"
            >
              <div style={{ flex: `0 1 20%` }}>DOB:</div>
              {values.month}/{values.day}/{values.year}
            </div>
            <div
              style={{ color: "#5D5D5D", fontSize: "14px" }}
              className="d-flex justify-content-start mb-1 mt-1"
            >
              <div style={{ flex: `0 1 20%` }}>Zip:</div> {values.zipCode}
            </div>
            <Link style={{ fontSize: "14px" }} to="/eligibility">
              Edit Info
            </Link>
          </div>
          <Form.Group className="form-spacing">
            <Form.Label>
              <b>Enter Health Plan Member ID:</b>
            </Form.Label>
            <Controller
              control={control}
              name="healthPlan"
              key="healthPlan"
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  style={{ width: "75%" }}
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  isInvalid={Boolean(errors.healthPlan)}
                  placeholder="enter health plan member ID"
                />
              )}
            />
            <div className="health-plan-missing d-flex flex-row justify-content-start mb-0 mt-3 mb-1">
              Don’t have your Health Plan Member ID?
            </div>
            <CustomerSupport />
          </Form.Group>
        </>
      ) : (
        <>
          <Form.Group className="form-spacing">
            <Form.Label>First Name:</Form.Label>
            <Controller
              control={control}
              name="firstName"
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  isInvalid={Boolean(errors.firstName)}
                  placeholder="First Name"
                />
              )}
            />
          </Form.Group>
          <Form.Group className="form-spacing">
            <Form.Label>Last Name:</Form.Label>
            <Controller
              control={control}
              name="lastName"
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  isInvalid={Boolean(errors.lastName)}
                  placeholder="Last Name"
                />
              )}
            />
          </Form.Group>
          <Form.Group className="form-spacing">
            <Form.Label>Date of Birth:</Form.Label>
            <Row className="justify-content-between">
              <Col>
                <Controller
                  control={control}
                  name="month"
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Select
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      isInvalid={Boolean(errors.month)}
                      placeholder="Month"
                    >
                      {getMonths()}
                    </Form.Select>
                  )}
                />
              </Col>
              <Col>
                <Controller
                  control={control}
                  name="day"
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Select
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      isInvalid={Boolean(errors.day)}
                      placeholder="Day"
                    >
                      {getDays()}
                    </Form.Select>
                  )}
                />
              </Col>
              <Col>
                <Controller
                  control={control}
                  name="year"
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Select
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      isInvalid={Boolean(errors.year)}
                      placeholder="Year"
                    >
                      {getYears()}
                    </Form.Select>
                  )}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="form-spacing">
            <Form.Label>5-Digit Zip Code:</Form.Label>
            <Controller
              control={control}
              name="zipCode"
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  isInvalid={Boolean(errors.zipCode)}
                  placeholder="Zip Code"
                />
              )}
            />
          </Form.Group>
          <Form.Group className="form-spacing">
            <Form.Label>Email Address:</Form.Label>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  isInvalid={Boolean(errors.email)}
                  placeholder="Email"
                />
              )}
            />
            <Controller
              control={control}
              name="healthPlan"
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  style={{ width: "75%" }}
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  hidden
                  isInvalid={Boolean(errors.healthPlan)}
                  placeholder="enter health plan member ID"
                />
              )}
            />
          </Form.Group>{" "}
        </>
      )}
      {/* <Controller
        control={control}
        name="subscribeNewsletter"
        render={({ field: { onChange, value, ref } }) => (
          <Form.Check
            className="form-spacing"
            type="checkbox"
            onChange={onChange}
            checked={value}
            ref={ref}
            isInvalid={Boolean(errors.subscribeNewsletter)}
            label="Sign up for our newsletter"
          />
        )}
      /> */}
      {multipleAccountsFoundForm && values.firstName !== "" ? (
        <div className="mt-4 d-flex flex-row justify-content-start">
          <Button
            variant="primary"
            type="submit"
            disabled={
              healthPlanWatch !== undefined && healthPlanWatch.length < 3
            }
            onClick={() => handleFormSubmit}
            className="form-button"
            style={{ width: "33%", marginRight: "24px" }}
          >
            <b>{loading ? "LOADING" : "Recheck Eligibility"}</b>
          </Button>
          <Button
            href={`${bootStore?.welldBaseReturnURL}${welldReturnToUrl}?isTivityRedirect=true&locationId=${locationId}`}
            className="form-button-link"
            variant="secondary"
            style={{}}
          >
            Return to Location Portal{"  "}
            <img
              src={AngleRight}
              alt="angleRIght"
              className="mw-100 text-center pb-1"
            />
          </Button>
        </div>
      ) : (
        <Button
          variant="primary"
          type="submit"
          disabled={loading}
          className="form-button mt-4"
        >
          <b>{loading ? "LOADING" : "Check Eligibility"}</b>
        </Button>
      )}
    </Form>
  );
}

export default EligibilityForm;
