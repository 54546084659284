import { create } from "zustand";
import { persist } from "zustand/middleware";
import { EligibilityFormTypes } from "../../components/EligibilityForm/EligibilityForm.types";

const useUserFormStore = create(
  persist<{
    firstName?: string | null;
    lastName?: string | null;
    month: string | null;
    day: string | null;
    year: string | null;
    zipCode: string | null;
    email: string | null;
    healthPlan: string | null;
    setUserForm: (userData: EligibilityFormTypes) => void;
  }>(
    (set) => ({
      firstName: null,
      lastName: null,
      month: null,
      day: null,
      year: null,
      zipCode: null,
      email: null,
      healthPlan: null,
      setUserForm: (userData: EligibilityFormTypes) => {
        set(userData);
      },
    }),
    {
      name: "userForm-store",
      getStorage: () => sessionStorage,
    }
  )
);

export default useUserFormStore;
