import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useLocationIdStore = create(
  persist<{
    locationId: string | null;
    welldReturnToUrl: string | null;
    setLocationId: (locationId: string) => void;
    setWelldReturnToUrl: (welldReturnToUrl: string) => void;
    hasHydrated: boolean;
    setHasHydrated: (state: boolean) => void;
  }>(
    (set) => ({
      locationId: null,
      welldReturnToUrl: null,
      setLocationId: (locationId: string) => {
        set({
          locationId,
        });
      },
      setWelldReturnToUrl: (welldReturnToUrl: string) => {
        set({
          welldReturnToUrl,
        });
      },
      hasHydrated: false,
      setHasHydrated: (state: boolean) => {
        set({
          hasHydrated: state,
        });
      },
    }),
    {
      name: "locationId-store",
      storage: createJSONStorage(() => sessionStorage),
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
    }
  )
);

export default useLocationIdStore;
