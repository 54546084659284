import { create } from "zustand";
import { ClientConfig } from "./useConfigStore.types";
// eslint-disable-next-line import/no-cycle
import { ApiClient } from "../../api/client";

const getClientConfig = async (partner: string): Promise<ClientConfig> => {
  try {
    const resp = await ApiClient.get(
      `/api/platform/v1/clients/partner-${partner}/config`
    );
    return resp.data.data as ClientConfig;
  } catch (e) {
    throw new Error("Could not get client config");
  }
};

const useConfigStore = create<{
  config: ClientConfig;
  getConfig: (partner: string) => Promise<void>;
}>((set) => ({
  config: [],
  getConfig: async (partner: string): Promise<any> => {
    set({
      config: await getClientConfig(partner),
    });
    return {};
  },
}));

export default useConfigStore;
