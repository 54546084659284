import React from "react";
// import useConfigStore from "../../stores/useConfigStore/useConfigStore";
import TivityLogo from "../../assets/tivity_logo.png";

function ClientLogo() {
  // const { config } = useConfigStore();

  // const logo = config.find((v) => v.key === "partner.logo")?.value ?? "";
  return (
    <div className="d-flex justify-content-center">
      <img src={TivityLogo} alt="logo" className="mw-100 text-center" />
    </div>
  );
}

export default ClientLogo;
