import React from "react";
import { Alert, Col, Container, Row, Stack } from "react-bootstrap";

function LocationIdDoesNotExist() {
  return (
    <Container className="h-100">
      <Row className="h-100 justify-content-center align-items-md-center pt-2 pt-md-0">
        <Col xs="auto">
          <Stack className="align-items-center">
            <Alert variant="danger">LocationId Does Not Exist</Alert>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default LocationIdDoesNotExist;
