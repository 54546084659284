import React from "react";
import { Routes, Route } from "react-router-dom";
import Eligibility from "../../pages/Eligibility/Eligibility";
import MasterLayout from "../../layouts/MasterLayout/MasterLayout";
import Success from "../../pages/Success/Success";
// import ClientSetter from "../../components/ClientSetter/ClientSetter";
import LocationIdSetter from "../../components/LocationIdSetter/LocationIdSetter";
import ClientRouteGuard from "../../components/ClientRouteGuard/ClientRouteGuard";
import Declined from "../../pages/Declined/Declined";
import PartialConfirm from "../../pages/PartialConfirm/PartialConfirm";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";

function Router() {
  return (
    <Routes>
      <Route
        element={
          <ClientRouteGuard>
            <MasterLayout />
          </ClientRouteGuard>
        }
      >
        <Route path="/eligibility" element={<Eligibility />} />
        <Route
          path="/multiple-accounts-found"
          element={<Eligibility multiple />}
        />
        {/* <Route path="/authorize" element={<Navigate to="/eligibility" />} /> */}
        <Route path="/success" element={<Success />} />
        <Route path="/declined" element={<Declined />} />
        <Route path="/partial/success" element={<PartialConfirm />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Route>
      <Route element={<MasterLayout />}>
        <Route path="/*" element={<LocationIdSetter />} />
      </Route>
    </Routes>
  );
}

export default Router;
