import React from "react";
// import PrivacyPolicyPDF from "../../assets/privacy-policy.pdf";

function PrivacyPolicy() {
  return (
    <iframe src="../../assets/privacy-policy.pdf" title="Privacy Policy" />
  );
}

export default PrivacyPolicy;
