import { useState, useEffect } from "react";

function CountDown({
  seconds,
  timeUp,
}: {
  seconds: number;
  timeUp: () => void;
}) {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) {
      timeUp();
      return;
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, timeUp]);

  return null;
  // return (
  //   <div>
  //     <h1>{timeLeft}</h1>
  //   </div>
  // );
}

export default CountDown;
