import { create } from "zustand";
import { AlertMessage } from "./useAlertStore.types";

const useAlertStore = create<{
  message: AlertMessage;
  setAlert: (message: AlertMessage) => void;
}>((set) => ({
  message: null,
  setAlert: (message) => {
    set({
      message,
    });
  },
}));

export default useAlertStore;
