import React from "react";
// import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import EligibilityForm from "../../components/EligibilityForm/EligibilityForm";
// import Logo from "../../assets/silversneakers.svg";
import ClientLogo from "../../components/ClientLogo/ClientLogo";

// eslint-disable-next-line react/require-default-props
function Eligibility({ multiple = false }: { multiple?: boolean }) {
  return (
    <Card
      className={`form-container${
        !multiple ? "" : "-multiple"
      } shadow border-0`}
    >
      <Card.Body>
        {!multiple && <ClientLogo />}
        <Card.Text
          className={`d-flex mt-2 justify-content-${
            !multiple ? "center" : "start"
          }`}
        >
          <h2>
            {multiple
              ? "Oops, multiple accounts were found"
              : "Check your Eligibility"}
          </h2>
        </Card.Text>
        {multiple ? (
          <Card.Text className="mt-2">
            If the information you provided below is correct, please enter your
            health plan member ID to help us find your account.
          </Card.Text>
        ) : (
          <Card.Text className="mt-2">
            Welcome! Please complete an eligibility check to see if you are
            eligible for a fitness benefit powered by Tivity Health&reg;.
          </Card.Text>
        )}
        {!multiple && (
          <Card.Text>
            Please enter your information below exactly as it has been
            communicated to your fitness benefit administrator (e.g. health plan
            or employer).
          </Card.Text>
        )}
        <EligibilityForm multipleAccountsFoundForm={multiple} />
        {/* <Card.Text className="d-flex justify-content-center mt-2">
          <span className="subtext">
            Already have an account? <Link to="/privacy-policy"> Log in</Link>
          </span>
        </Card.Text> */}
        {/* {!multiple && (
          <Card.Text className="d-flex justify-content-center mt-2">
            <span className="subtext">
              By clicking &quot;Check Eligibility&quot;, you are indicating that
              you have read and acknowledge the{" "}
              <a
                href="https://www.silversneakers.com/privacy-policy-terms/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy and Terms of Use
              </a>
            </span>
          </Card.Text>
        )} */}
      </Card.Body>
    </Card>
  );
}

export default Eligibility;
